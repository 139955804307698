import React from 'react'

const PoliticaPrivacidadeText = () => {
	return (
		<>
			<p className='text-lg text-gray-900 text-center mb-4'>Política de Privacidade</p>
			<span>
				{' '}
				A Política de Privacidade da Valid foi criada para demonstrar o compromisso da Companhia com a segurança e a
				privacidade de informações coletadas dos usuários de serviços interativos aqui disponíveis.
				<br />
				<br />
				Caso você forneça alguma informação, essa política procura esclarecer como a Valid coleta e trata suas
				informações individuais.
				<br />
				<br />
				Recomenda-se a verificação temporária dessa política, que está sujeita a alterações sem prévio aviso.
				<br />
				<br />
				Quaisquer informações que os usuários passarem serão coletadas e guardadas de acordo com padrões rígidos de
				segurança e confidencialidade.
				<br />
				<br />
				As informações pessoais que forem passadas à Companhia pelos usuários serão coletadas por meios éticos e legais,
				podendo ter um ou mais propósitos, sobre os quais os usuários serão informados.
				<br />
				<br />
				Os usuários serão avisados de quais informações suas estarão sendo coletadas antes do instante dessa coleta,
				ficando a opção de escolha para fornecimento ou não dessas informações sob responsabilidade do usuário, o qual
				também terá ciência das consequências de sua decisão.
				<br />
				<br />
				A menos que a Valid receba determinação legal ou judicial, suas informações nunca serão transferidas a terceiros
				ou usadas para finalidades diferentes daquelas para as quais foram coletadas.
				<br />
				<br />
				O acesso às informações coletadas está restrito apenas a funcionários autorizados para o uso adequado dessas
				informações. Os funcionários que se utilizarem indevidamente dessas informações, ferindo essa Política de
				Privacidade, estarão sujeitos às penalidades do processo disciplinar da Valid.
				<br />
				<br />
				A Companhia manterá íntegras as informações que forem fornecidas pelos visitantes.
				<br />
				<br />
				Este website contém links ou frames de outros sites, que podem ou não ser parceiros da Companhia e aliados.
				Esses links e frames são disponibilizados buscando, tão somente, proporcionar mais um benefício para os
				usuários. Vale ressaltar que a inclusão desses links e frames não significa que a Valid tenha conhecimento,
				concorde ou seja responsável por eles ou por seus respectivos conteúdos. Portanto, a Valid não pode ser
				responsabilizada por eventuais perdas ou danos sofridos em razão de utilização dos referidos links ou frames.
				<br />
				<br />
				Sempre que outras organizações forem contratadas para prover serviços de apoio, será exigida a adequação aos
				padrões de privacidade da Valid.
				<br />
				<br />
				Para fins administrativos, eventualmente a Companhia poderá utilizar “cookies”(*), sendo que o usuário pode, a
				qualquer instante, ativar em seu navegador mecanismos para informá-lo quando os mesmos estiverem acionados ou
				para evitar que sejam acionados.
				<br />
				<br />
				Outras importantes informações sobre os termos e condições de utilização deste website estão disponíveis em
				Condições de Uso.
				<br />
				<br />
				(*) Cookie: pequeno arquivo colocado em seu computador para rastrear movimentos dentro dos websites.{' '}
			</span>
		</>
	)
}

export default PoliticaPrivacidadeText
