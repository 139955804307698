import React from 'react'
import Layout from '../components/layout'
import PoliticaPrivacidadeText from '../components/PoliticaPrivacidadeText'
import Header from '../components/header'
import SEO from '../components/seo'

const PoliticaPage = () => {
	return (
		<Layout>
			<Header />
			<SEO title='Política de Privacidade' />
			<div className='p-8 text-gray-700 leading-7'>
				<PoliticaPrivacidadeText />
			</div>
		</Layout>
	)
}

export default PoliticaPage
